import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming react-router-dom is used for navigation
import './VehicleDataTable.css'; // Importing the CSS file
import mod1 from '../../assets/mod1.png';
import mod2 from '../../assets/mod2.png';
import { FaPencilAlt } from 'react-icons/fa'; // Importing pencil icon from react-icons

const VehicleDataTable = () => {
    const [vehicles, setVehicles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [newVehicle, setNewVehicle] = useState('');
    const navigate = useNavigate(); // For navigation

    const companyID = localStorage.getItem('userID');

    // Fetch vehicle data from the API
    useEffect(() => {
        const fetchVehicleData = async () => {
            try {
                const response = await axios.get(
                    `https://fastagtracking.com/customulip/vehicle/status/${companyID}`
                );
                setVehicles(response.data.data); // Set the vehicles array
                setFilteredVehicles(response.data.data); // Initially, show all vehicles
            } catch (error) {
                console.error('Error fetching vehicle data:', error);
            }
        };

        fetchVehicleData();
    }, [companyID]);

    // Handle search input and filter the vehicle list based on the search term
    const handleSearch = () => {
        if (searchTerm) {
            const filtered = vehicles.filter((vehicle) =>
                vehicle.rc_regn_no.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredVehicles(filtered);
        } else {
            setFilteredVehicles(vehicles); // Reset to all vehicles if no search term
        }
    };

    // Handle adding a new vehicle
    const handleAddVehicle = async () => {
        if (newVehicle) {
            try {
                const response = await axios.post('https://fastagtracking.com/customulip/vehicle-status', {
                    company_id: companyID,
                    vehiclenumber: newVehicle,
                });
                console.log('Vehicle added:', response.data);
                
                // Optionally, refetch or update vehicle data here
                setNewVehicle(''); // Clear the input field after adding
                window.location.reload()
            } catch (error) {
                console.error('Error adding vehicle:', error);
            }
        }
    };

    // Function to check if a date is expired
    const isExpired = (dateStr) => {
        if (!dateStr) return false;
        const date = new Date(dateStr);
        const today = new Date();
        return date < today;
    };

    // Function to apply conditional styling
    const getCellClassName = (dateStr) => {
        return isExpired(dateStr) ? 'expired' : '';
    };

    // Navigate to fastag or vaahan page based on vehicle number
    const handleCardClick = (vehicleNumber) => {
        navigate(`/fastag/${vehicleNumber}`);
    };

    const handleImageClick = (e, vehicleNumber) => {
        e.stopPropagation();
        navigate(`/vahan/${vehicleNumber}`);
    };

    // Handle edit action
    const handleEditClick = (e, vehicleNumber) => {
        e.stopPropagation();
        // Add your edit logic here
        console.log('Editing vehicle:', vehicleNumber);
    };

    return (
        <div className="vehicle-container">
            <h2 className="vehicle-heading">Vehicle Information</h2>

            {/* Search and Add Vehicle Section */}
            <div className="action-section">
                <div className="search-section">
                    <input
                        type="text"
                        placeholder="Search by Vehicle No."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                    <button onClick={handleSearch} className="search-btn">Search</button>
                </div>

                <div className="add-vehicle-section">
                    <input
                        type="text"
                        placeholder="Enter New Vehicle No."
                        value={newVehicle}
                        onChange={(e) => setNewVehicle(e.target.value)}
                        className="add-input"
                    />
                    <button onClick={handleAddVehicle} className="add-btn">Add Vehicle</button>
                </div>
            </div>

            {/* Vehicle Table */}
            {filteredVehicles.length > 0 ? (
                <table className="vehicle-table">
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Vehicle No</th>
                            <th>Fit Upto</th>
                            <th>Insurance Upto</th>
                            <th>NP Upto</th>
                            <th>Permit Valid Upto</th>
                            <th>PUCC Upto</th>
                            <th>Tax Upto</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredVehicles.map((vehicle, index) => (
                            <tr key={vehicle._id} onClick={() => handleCardClick(vehicle.rc_regn_no)}>
                                <td>{index + 1}</td>
                                <td>{vehicle.rc_regn_no}</td>
                                <td className={getCellClassName(vehicle.rc_fit_upto)}>{vehicle.rc_fit_upto}</td>
                                <td className={getCellClassName(vehicle.rc_insurance_upto)}>{vehicle.rc_insurance_upto}</td>
                                <td className={getCellClassName(vehicle.rc_np_upto)}>{vehicle.rc_np_upto}</td>
                                <td className={getCellClassName(vehicle.rc_permit_valid_upto)}>{vehicle.rc_permit_valid_upto}</td>
                                <td className={getCellClassName(vehicle.rc_pucc_upto)}>{vehicle.rc_pucc_upto}</td>
                                <td className={getCellClassName(vehicle.rc_tax_upto)}>{vehicle.rc_tax_upto}</td>
                                <td>
                                    <div className="actions flex">
                                        <div className="w-[70px] cursor-pointer h-[30px] flex justify-center items-center rounded-full border border-black bg-[#EDEDED]">
                                            <img src={mod1} className="w scale-[0.8]" alt="Fastag" />
                                        </div>
                                        <div
                                            className="w-[70px] h-[30px] cursor-pointer flex justify-center mx-2 items-center rounded-full border border-black bg-[#EDEDED]"
                                            onClick={(e) => handleImageClick(e, vehicle.rc_regn_no)}
                                        >
                                            <img src={mod2} className="scale-[0.8]" alt="Vaahan" />
                                        </div>
                                        <div
                                            className="w-[70px] h-[30px] cursor-pointer flex justify-center items-center rounded-full border border-black bg-[#EDEDED]"
                                            onClick={(e) => handleEditClick(e, vehicle.rc_regn_no)}
                                        >
                                            <FaPencilAlt className="scale-[0.8]" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="no-data-text">No vehicle data available</p>
            )}
        </div>
    );
};

export default VehicleDataTable;
